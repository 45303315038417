var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.current)?_c('div',{attrs:{"tabindex":"-1"}},[(_vm.targeted.length === 0)?_c('h3',{staticClass:"red text-center"},[_vm._v("Select one or more targets")]):(_vm.current.entityType !== 'player')?[(['npc', 'environment', 'companion'].includes(_vm.current.entityType))?[_c('q-tabs',{attrs:{"dark":_vm.$store.getters.theme === 'dark',"inline-label":"","outside-arrows":"","mobile-arrows":"","dense":"","no-caps":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.action_types),function({ name, label, type },index){return [(_vm.current[type] && _vm.current[type].length)?_c('q-tab',{key:`tab-${index}`,attrs:{"name":name,"label":label}}):_vm._e()]})],2),_c('q-tab-panels',{staticClass:"bg-transparent",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.action_types),function({ name, type },type_index){return _c('q-tab-panel',{key:`panel-${type_index}`,attrs:{"name":name}},[(type === 'legendary_actions' && _vm.current.legendary_count)?_c('div',{staticClass:"limited"},[_vm._v("\n\t\t\t\t\t\tActions used\n\t\t\t\t\t\t"),_c('div',{staticClass:"slots"},_vm._l((_vm.current.legendary_count),function(i){return _c('span',{key:`legendary-${i}`,staticClass:"mr-1",on:{"click":function($event){_vm.current.limited_uses['legendary_actions'] &&
									_vm.current.limited_uses['legendary_actions'].legendaries_used >= i
										? _vm.spendLimited('legendary_actions', 'legendaries_used', true)
										: _vm.spendLimited('legendary_actions', 'legendaries_used')}}},[_c('i',{staticClass:"far",class:_vm.current.limited_uses['legendary_actions'] &&
										_vm.current.limited_uses['legendary_actions'].legendaries_used >= i
											? 'fa-dot-circle'
											: 'fa-circle',attrs:{"aria-hidden":"true"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.current.limited_uses["legendary_actions"] &&
										_vm.current.limited_uses["legendary_actions"].legendaries_used >= i
											? "Regain action"
											: "Spend action")+"\n\t\t\t\t\t\t\t\t")])],1)}),0)]):_vm._e(),(_vm.current[type])?_c('q-list',{class:`accordion`,attrs:{"dark":_vm.$store.getters.theme === 'dark',"square":""}},_vm._l((_vm.current[type]),function(action,action_index){return _c('q-expansion-item',{key:`action-${action_index}`,attrs:{"dark":_vm.$store.getters.theme === 'dark',"switch-toggle-side":"","expand-icon-class":"hidden-toggle","group":type,"name":name},on:{"focus":function($event){return _vm.focusButton(action_index)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('q-item-section',{class:_vm.checkAvailable(type, action_index, action) ? '' : 'is-disabled'},[_c('q-item-label',[_c('strong',[_vm._v(_vm._s(action.name))]),_c('span',{staticClass:"neutral-3"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(action.recharge
													? `(Recharge ${
															action.recharge === "rest"
																? "after a Short or Long Rest"
																: action.recharge
														})`
													: ``)+"\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(action.limit
													? `(${action.limit}/${
															action.limit_type ? action.limit_type.capitalize() : `Day`
														})`
													: ``)+"\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(action.legendary_cost > 1
													? `(Costs ${action.legendary_cost} Actions)`
													: ``)+"\n\t\t\t\t\t\t\t\t\t\t")])]),(
											action.action_list &&
											action.action_list[0] &&
											action.action_list[0].type !== 'other'
										)?_c('q-item-label',{attrs:{"caption":""}},[(!_vm.isNil(action.action_list[0].attack_bonus))?_c('span',[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(action.action_list[0].attack_bonus < 0
													? `-${Math.abs(action.action_list[0].attack_bonus)}`
													: `+${action.action_list[0].attack_bonus}`)+"\n\t\t\t\t\t\t\t\t\t\t\tto hit\n\t\t\t\t\t\t\t\t\t\t")]):_vm._e(),(action.action_list[0].rolls)?_c('span',_vm._l((action.action_list[0].rolls),function(roll,roll_index){return _c('span',{key:`roll-${action_index}-${roll_index}`},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t("),_c('i',{class:[
														action.action_list[0].type === 'healing'
															? 'fas fa-heart green'
															: _vm.damage_type_icons[roll.damage_type],
														roll.damage_type,
													],attrs:{"aria-hidden":"true"}}),_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(roll.dice_count || "")+_vm._s(roll.dice_type ? `d${roll.dice_type}` : ``)),(roll.fixed_val && roll.dice_count)?[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(roll.fixed_val < 0
															? `- ${Math.abs(roll.fixed_val)}`
															: `+ ${roll.fixed_val}`)+") ")]:[_vm._v(_vm._s(roll.fixed_val)+")")],_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(roll_index + 1 < action.action_list[0].rolls.length ? "+" : "")+"\n\t\t\t\t\t\t\t\t\t\t\t\t"),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(action.action_list[0].type === "healing"
															? "Healing"
															: `${roll.damage_type.capitalize()} damage`)+"\n\t\t\t\t\t\t\t\t\t\t\t\t")])],2)}),0):_vm._e(),(action.reach)?_c('span',[_c('span',{staticClass:"blue"},[_vm._v("|")]),_vm._v(" "+_vm._s(action.reach)),_c('small',{staticClass:"neutral-2"},[_vm._v("ft.")]),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v(" Reach ")])],1):_vm._e(),(action.range)?_c('span',[_c('span',{staticClass:"blue"},[_vm._v("|")]),_vm._v(" "+_vm._s(action.range)),_c('small',{staticClass:"neutral-2"},[_vm._v("ft.")]),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v(" Range ")])],1):_vm._e(),(
												action.action_list[0].type === 'save' && action.action_list[0].save_dc
											)?_c('span',[_c('span',{staticClass:"blue"},[_vm._v("|")]),(action.action_list[0].save_ability)?_c('span',[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(action.action_list[0].save_ability.substring(0, 3).toUpperCase())+"\n\t\t\t\t\t\t\t\t\t\t\t")]):_vm._e(),_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(action.action_list[0].save_dc)+"\n\t\t\t\t\t\t\t\t\t\t")]):_vm._e(),(action.aoe_type)?_c('span',[_c('span',{staticClass:"blue"},[_vm._v("|")]),_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(action.aoe_size)),_c('small',{staticClass:"neutral-2"},[_vm._v("ft.")]),_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(action.aoe_type.capitalize())+"\n\t\t\t\t\t\t\t\t\t\t\t"),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\tArea of effect\n\t\t\t\t\t\t\t\t\t\t\t")])],1):_vm._e()]):_vm._e()],1),(
										action.action_list &&
										action.action_list[0] &&
										action.action_list[0].type !== 'other' &&
										action.action_list[0].rolls &&
										action.action_list[0].rolls.length
									)?_c('q-item-section',{attrs:{"avatar":""}},[_c('div',{staticClass:"roll-wrapper",class:{
											'step-highlight': _vm.demo && _vm.follow_tutorial && _vm.get_step('run', 'roll'),
										}},[(_vm.demo && action_index == 0)?_c('TutorialPopover',{attrs:{"tutorial":"run","step":"roll","position":"right","offset":[20, 0]}}):_vm._e(),_c('hk-roll-action',{ref:action_index,refInFor:true,attrs:{"action":action,"tooltip":`Roll ${action.name}`,"disabled":!_vm.checkAvailable(type, action_index, action)},on:{"roll":function($event){return _vm.startRoll(...arguments, action_index, action, type)}}},[_c('span',{staticClass:"roll-button"})])],1)]):(action.limit || action.recharge || action.legendary_cost)?_c('q-item-section',{attrs:{"avatar":""}},[(action.legendary_cost || action.recharge)?[(_vm.checkAvailable(type, action_index, action))?_c('div',{staticClass:"blue",on:{"click":function($event){$event.stopPropagation();return _vm.spendLimited(
													type,
													action.legendary_cost ? 'legendaries_used' : action_index,
													false,
													action.legendary_cost ? action.legendary_cost : 1
												)}}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\tUse\n\t\t\t\t\t\t\t\t\t\t")]):_c('i',{staticClass:"fas fa-ban neutral-2",attrs:{"aria-hidden":"true"}})]:_c('div',{staticClass:"slots"},_vm._l((parseInt(action.limit)),function(i){return _c('span',{key:`legendary-${i}`,staticClass:"mr-1",on:{"click":function($event){$event.stopPropagation();_vm.current.limited_uses[type] &&
												_vm.current.limited_uses[type][action_index] >= i
													? _vm.spendLimited(type, action_index, true)
													: _vm.spendLimited(type, action_index)}}},[_c('i',{staticClass:"far",class:_vm.current.limited_uses[type] &&
													_vm.current.limited_uses[type][action_index] >= i
														? 'fa-dot-circle'
														: 'fa-circle',attrs:{"aria-hidden":"true"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.current.limited_uses[type] &&
													_vm.current.limited_uses[type][action_index] >= i
														? "Regain"
														: "Spend")+"\n\t\t\t\t\t\t\t\t\t\t\t")])],1)}),0)],2):_vm._e()]},proxy:true}],null,true)},[(action.desc)?_c('div',{staticClass:"accordion-body description"},[_c('hk-dice-text',{attrs:{"input_text":action.desc}}),(!_vm.checkAvailable(type, action_index, action) && action.recharge)?_c('div',{staticClass:"blue pointer mt-2",on:{"click":function($event){return _vm.spendLimited(type, action_index, true)}}},[_vm._v("\n\t\t\t\t\t\t\t\t\tRegain use\n\t\t\t\t\t\t\t\t")]):_vm._e()],1):_vm._e()])}),1):_c('p',[_vm._v("Nothing found.")])],1)}),1)]:_vm._e()]:_c('p',[_vm._v("\n\t\tMost players want to roll their own attacks, you probably shouldn't take that away from them.\n\t\t;)\n\t")]),_c('q-dialog',{model:{value:(_vm.projectile_dialog),callback:function ($$v) {_vm.projectile_dialog=$$v},expression:"projectile_dialog"}},[_c('Projectiles',{attrs:{"projectile-count":_vm.rollObject.projectiles},on:{"cancel":_vm.cancelRoll,"roll":_vm.roll}})],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }